<template>
  <v-app>

    <v-overlay v-if="loading">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate :size="70" :width="7" color="black"></v-progress-circular>
          </v-layout>
        </v-container>
    </v-overlay>

    <v-overlay
        v-if="loadingError && !loading  && !noToken"
    >
      <v-alert
          prominent
          type="error"
          v-if="loadingError && !loading && !noToken"
      >
        <v-row align="center">
          <v-col class="grow" >
            Sorry! Something went wrong!
          </v-col>
          <v-col class="shrink">
            <v-btn icon onclick="location.reload()" dense><v-icon>fal fa-redo mr-2</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-layout fill-height bgcolor="primary">

      </v-layout>
    </v-overlay>

    <v-layout fill-height v-if="noToken">
      <v-container fill-height class="primary">
        <v-layout justify-center align-center>
          <div>
          <div class="ml-10">
            <img src="/assets/renault_logo.png" />
          </div>
          <div justify-center class="text-center">
            <h1>Customer Portal</h1>
          </div>
          </div>
        </v-layout>
      </v-container>
    </v-layout>

    <v-app-bar
      app
      color="primary"
      v-if="!loading && !loadingError"
    >
      <img src="/assets/renault_logo.png" class="mr-3" height="90%" />
      <v-toolbar-title >
        {{ translations.appName }}
        <div class="text-caption">
          {{ dealer.name }}
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>


    </v-app-bar>
    <v-main v-if="!loading && !loadingError">
      <v-stepper non-linear vertical class="elevation-0" v-model="e1">

        <!-- Auftragsstatus -->
        <v-stepper-step
            key="`1-step`"
            step="1"
            :rules="[() => true ]"
            editable
            edit-icon = "$success"
            complete-icon="$success"
            error-icon=""

        >
          {{ translations.stepOrder }}
        </v-stepper-step>
        <v-stepper-content
            step="1"
        >
          <v-row v-if="dealer.video === true && Object.keys(featuredVideo).length > 0 ">
            <v-spacer></v-spacer>
          <v-card elevation="0" class="text-center" style="max-width: 450px;">
            <v-rating
                hover
                length="5"
                size="12"
                :value="Number(featuredVideo.rating)"
                :readonly="Number(featuredVideo.rating) > 0"
                @input="addRating($event, featuredVideo.id)"
            ></v-rating>

              <div class="video_wrapper" style="position: relative;" >
                <video
                    :src="ApiUrl+featuredVideo.src"
                    :poster="ApiUrl+featuredVideo.poster"
                    id = "featuredVideo"
                    class="white--text align-end"
                    width="100%"
                    style="max-width: 450px;"
                ></video>
              </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon onClick="document.getElementById('featuredVideo').play()">
                <v-icon>fal fa-play-circle</v-icon>
              </v-btn>
              <v-btn icon onClick="document.getElementById('featuredVideo').pause()">
                <v-icon>fal fa-pause-circle</v-icon>
              </v-btn>
              <v-btn icon onClick="document.getElementById('featuredVideo').pause()">
                <v-icon>fal fa-stop-circle</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
            <v-spacer></v-spacer>
          </v-row>
          <v-card
              class="mb-12"
              height="auto"
              flat
          >

            <v-simple-table fixed-header height="auto">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">{{ translations.tableHeaderDescription }}</th>
                  <th class="text-center" v-if="dealer.video === true && videos.length > 0">{{ translations.tableHeaderVideo }}</th>
                  <th class="text-center">{{ translations.tableHeaderPriority }}</th>
                  <th class="text-right">{{ translations.tableHeaderApprovedTotal }}</th>
                  <th class="text-right">{{ translations.tableHeaderToDecideTotal }}</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in interventions" :key="item.rowid">
                  <td>
                    {{ item.name }}
                  </td>
                  <td v-if="dealer.video === true  && videos.length > 0"><v-badge
                      color="green"
                      icon="fal fa-video"
                      :value="item.video"
                      v-if="item.video === true"
                  ></v-badge></td>
                  <td style="min-width: 150px;" class="text-center">
                    <v-tooltip top v-if="item.color">
                      <template v-slot:activator="{ on, attrs }">
                      <v-icon  small :color="item.color" v-bind="attrs"
                              v-on="on">fas fa-circle</v-icon>
                      </template>
                      <span>{{ translations.immediately }}</span>
                    </v-tooltip>
                  </td>
                  <td style="min-width: 120px;" class="text-right"><v-chip v-if="item.needsCustomerApproval===false && item.price > 0" class="d-flex justify-end" label>{{ addRounding(item.price) }} €</v-chip></td>
                  <td style="min-width: 120px;" class="text-right"><v-chip v-if="item.needsCustomerApproval===true && item.price > 0" class="d-flex justify-end"  label>{{ addRounding(item.price) }} €</v-chip></td>
                  <td class="text-right">
                    <v-switch :input-value="!item.needsCustomerApproval" v-show="!item.alreadyApproved" @click="approve=true; item.needsCustomerApproval = !item.needsCustomerApproval;toggleIntervention(item.rowid)" inset color="success"></v-switch>
                  </td>
                </tr>
                <tr v-for="item in potentials" :key="item">
                  <td>
                    {{ item.name }}
                  </td>
                  <td v-if="dealer.video === true && videos.length > 0"><v-badge
                      color="green"
                      icon="fal fa-video"
                      :value="item.video"
                      v-if="item.video === true"
                  ></v-badge></td>
                  <td style="min-width: 150px;" class="text-center">
                    <v-tooltip top v-if="item.color">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small :color="item.color" v-bind="attrs"
                                 v-on="on">fas fa-circle</v-icon>
                      </template>
                      <span>{{ item.priorityDescription }}</span>
                    </v-tooltip>

                    <v-icon v-if="item.color" small :color="item.color">fas fa-circle</v-icon></td>
                  <td style="min-width: 120px;" class="text-right"><v-chip v-if="item.needsCustomerApproval===false && item.price > 0" class="d-flex justify-end" label>{{ addRounding(item.price) }} €</v-chip></td>
                  <td style="min-width: 120px;" class="text-right"><v-chip v-if="item.needsCustomerApproval===true && item.price > 0" class="d-flex justify-end"  label>{{ addRounding(item.price) }} €</v-chip></td>
                  <td class="text-right">
                    <v-switch :input-value="!item.needsCustomerApproval" v-show="!item.alreadyApproved" @click="approve=true; item.needsCustomerApproval = !item.needsCustomerApproval;togglePotential(item.potential_id)" inset color="success"></v-switch>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th>TOTAL</th>
                  <th v-if="dealer.video === true && videos.length > 0"></th>
                  <th></th>
                  <th class="text-right"><v-chip class="d-flex justify-end" color="green" text-color="white" label>{{ Number(sumAcceptedInterventions).toLocaleString() }} €</v-chip></th>
                  <th class="text-right"><v-chip class="d-flex justify-end" v-if="sumUnacceptedInterventions>0" color="orange" text-color="white" label>{{ Number(sumUnacceptedInterventions).toLocaleString() }} €</v-chip></th>
                  <th></th>
                </tr>
                </tfoot>

              </template>

            </v-simple-table>
            <v-row no-gutters>
              <v-textarea class="mt-3" v-model="customerMessage" :label="translations.inlineTextareaMessage" outlined></v-textarea>
            </v-row>
            <v-row no-gutters >
              <v-col class="pa-1"><v-btn @click="sendChanges()" block color="accent" ><v-icon dense left>fal fa-envelope</v-icon>
                {{ translations.buttonTextApprooveDeny }}</v-btn></v-col>
            </v-row>
          </v-card>
        </v-stepper-content>

        <!-- Termininfo -->
        <v-stepper-step
            key="`2-step`"
            step="2"
            editable
        >
          {{ translations.stepContact }} <span v-if="appointment.entryDate">{{ translations.stepExtensionAppointment }}</span>
        </v-stepper-step>
        <v-stepper-content
            step="2"
        >
          <v-row no-gutters class="d-flex">
            <v-col class="pa-3">
              <v-card
                  class="mx-auto"
                  outlined
              >
                <v-list-item style="min-height: 200px;">
                  <v-list-item-content >
                    <div class="overline">{{ translations.serviceAdvisorHeadline }}</div>
                    <v-list-item-title>{{ serviceAdvisor.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{dealer.name}}</v-list-item-subtitle>
                    <v-list-item-action-text>{{dealer.street}}<br>{{dealer.postal}} {{dealer.city}}</v-list-item-action-text>

                  </v-list-item-content>

                  <v-list-item-avatar
                      tile
                      size="80"
                  >
                    <v-icon size="80">fal fa-user-headset</v-icon>
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-btn color="accent" :href="'mailto:' + serviceAdvisor.email"><v-icon dense left>fal fa-envelope</v-icon></v-btn>
                  <v-btn color="accent" :href="'tel:' + serviceAdvisor.phone"><v-icon dense left>fal fa-phone</v-icon></v-btn>
                  <!--<v-btn :href="'tel:' + serviceAdvisor.phone"><v-icon dense left>fal fa-map</v-icon> Route</v-btn>-->
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col class="pa-3">
              <v-card
                  class="mx-auto"
                  v-if="appointment.entryDate != null || appointment.restitutionDate != null"
                  outlined
              >
                <v-list-item style="min-height: 200px;" >
                  <v-list-item-content>
                    <div class="overline mb-4">{{ translations.appointmentHeadline }}</div>
                    <v-list-item-title class="headline-2 mb-1">{{ translations.reception }}</v-list-item-title>
                    <div>
                      <v-chip
                          color="accent"
                          label
                          text-color="white"
                      >
                        <v-icon dense left>fal fa-calendar-day</v-icon>
                        {{ appointment.entryDate }}
                      </v-chip>
                    </div>
                    <v-list-item-title class="headline-2 mb-1">{{ translations.restitution }}</v-list-item-title>
                    <div>
                      <v-chip
                          color="accent"
                          label
                          text-color="white"
                      >
                        <v-icon dense left>fal fa-calendar-day</v-icon>
                        {{ appointment.restitutionDate }}
                      </v-chip>
                    </div>
                  </v-list-item-content>
                  <v-list-item-avatar
                      tile
                      size="80"
                  >
                    <v-icon size="80">fal fa-calendar-alt</v-icon>
                  </v-list-item-avatar>

                </v-list-item>
                <v-card-actions class="justify-end">
                  <!--
                  <v-btn ><v-icon dense left>fal fa-sync</v-icon> Request Change</v-btn>
                  <v-btn color="error" dark><v-icon dense left>fal fa-times-circle</v-icon> Cancel</v-btn>

                  <v-btn color="error" right><v-icon dense left>fal fa-times-circle</v-icon> Cancel</v-btn>
                   -->
                </v-card-actions>


              </v-card>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="pa-3"></v-col>
            <v-col class="pa-3"></v-col>
          </v-row>

        </v-stepper-content>

        <!-- Videos -->
        <v-stepper-step
            :key="`3-step`"
            step="3"
            :rules="[() => true ]"
            editable
            v-if="dealer.video === true && videos.length > 0"
        >
          {{ translations.stepAdditionVideos }}
        </v-stepper-step>
        <v-stepper-content
            step="3"
            v-if="dealer.video === true && videos.length > 0"
        >
          <v-container fluid>
              <v-row dense>
                <v-col
                    v-for="video in videos"
                    :key="video.id"
                    min-width="300px"
                    max-width="300px"

                >
                  <videoplayer
                      :videoid="video.id"
                      :src="ApiUrl+video.src"
                      :poster="ApiUrl+video.poster"
                      :rating="video.rating"
                      :addRating ="addRating.bind(this)"
                  ></videoplayer>

                </v-col>
              </v-row>
            </v-container>
        </v-stepper-content>


      </v-stepper>
      <v-dialog
          v-model="ratingDialog"
          max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            Rating
          </v-card-title>

          <v-card-text>
            Do you really want to give {{ ratedValue }} of 5 stars for this video?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="green darken-1"
                text
                @click="ratingDialog = false"
            >
              No
            </v-btn>

            <v-btn
                color="green darken-1"
                text
                @click="ratingDialog = false; sendRating(ratedValue);"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay
          :value="overlay"
      >
        <v-snackbar
            v-model="votingMessage"
            timeout="2000"
            centered
            color="success"
            content-class="text-center"
        >
          <h3>{{translations.message_thanksforrating}}</h3>

        </v-snackbar>

        <v-snackbar
            v-model="approvalMessage"
            timeout="2000"
            centered
            color="success"
            content-class="text-center"
        >
          <h3>{{ translations.message_thankyourforyourmessage }}</h3>

        </v-snackbar>

      </v-overlay>
    </v-main>

  </v-app>
</template>

<script>

const APIURL = 'https://www.mpo-renault.com';
//const APIURL = 'localhost:8081';

import axios from 'axios';
import videoplayer from './components/videoplayer.vue';

export default {
  name: 'App',

  components: {
    videoplayer
  },
  computed:{
    sumAcceptedInterventions: function(){

      let total = [];

      // eslint-disable-next-line no-unused-vars
      Object.entries(this.interventions).forEach(([key, val]) => {
        if(val.needsCustomerApproval === false) {
          total.push(this.addRounding(val.price)) // the value of the current key.
        }
      });
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.potentials).forEach(([key, val]) => {
        if(val.needsCustomerApproval === false) {
          total.push(this.addRounding(val.price)) // the value of the current key.
        }
      });

      return total.reduce(function(total, num){ return total + num }, 0);

    },
    sumUnacceptedInterventions: function(){

      let total = [];

      // eslint-disable-next-line no-unused-vars
      Object.entries(this.interventions).forEach(([key, val]) => {
        if(val.needsCustomerApproval === true) {
          total.push(this.addRounding(val.price)) // the value of the current key.
        }
      });

      // eslint-disable-next-line no-unused-vars
      Object.entries(this.potentials).forEach(([key, val]) => {
        if(val.needsCustomerApproval === true) {
          total.push(this.addRounding(val.price)) // the value of the current key.
        }
      });

      return total.reduce(function(total, num){ return total + num }, 0);

    }
  },
data() {
  return {
    noToken:false,
    loading: true,
    loadingError: true,
    drawer: null,
    serviceAdvisor:null,
    appointment:null,
    e1: 1,
    steps: null,
    interventions: [],
    potentials: [],
    videos: null,
    featuredVideo:null,
    dealer : null,
    approve : false,
    selectedRowIds:[],
    selectedPotentialIds:[],
    unselectedRowIds:[],
    ratingDialog : false,
    ratedValue : 0,
    ratedVideoId : 0,
    votingMessage:false,
    approvalMessage:false,
    encryptedCustomerPortalId:null,
    overlay:false,
    customerMessage:'',
    ApiUrl : APIURL,
    translations : null
  }
},
  watch : {
    votingMessage (val) {
      if(!val){
        this.overlay = false
      }
    }
  },
  methods: {
    sendChanges: function(){
      this.loading = true;
        axios
            .post(APIURL+'/CustomerPortal/acceptInterventions/'+this.encryptedCustomerPortalId,
                {'selectedItems':this.selectedRowIds,'unselectedItems':this.unselectedRowIds,'selectedPotentials':this.selectedPotentialIds,'customerMessage':this.customerMessage},
                { headers: {'Content-Type': 'multipart/form-data' } })
            .then( response => {
                 console.log(response.data.message);
            }
            )
            .catch(error=>{
              console.log(error);
            })
            .finally( () => {
              this.loading = false;
              this.approvalMessage=true;
              this.overlay = true;
              setTimeout(function(){ location.reload() }, 2500);
            })

    },
    toggleIntervention: function(rowID) {
      if(this.selectedRowIds.includes(rowID)) {
        this.selectedRowIds = this.selectedRowIds.filter(function(value){
          return value !== rowID;
        });
      } else {
        this.selectedRowIds.push(rowID);
      }
      if(this.unselectedRowIds.includes(rowID)) {
        this.unselectedRowIds = this.unselectedRowIds.filter(function(value){
          return value !== rowID;
        });
      } else {
        this.unselectedRowIds.push(rowID);
      }
    },
    togglePotential: function(rowID) {
      if(this.selectedPotentialIds.includes(rowID)) {
        this.selectedPotentialIds = this.selectedPotentialIds.filter(function(value){
          return value !== rowID;
        });
      } else {
        this.selectedPotentialIds.push(rowID);
      }

    },
    addRating(value, id) {
      this.ratedValue = value;
      this.ratedVideoId = id;
      this.ratingDialog = true;
    },
    sendRating(){
      axios
          .post(APIURL+'/CustomerPortal/rateVideo/'+this.encryptedCustomerPortalId,{'videoId':this.ratedVideoId,'rating':this.ratedValue},{ headers: {'Content-Type': 'multipart/form-data' } })
          .then( response => {
                console.log(response.data.message);
              }
          )
          .catch(error=>{
            console.log(error);
          })
          .finally( () => {
            this.votingMessage=true;
            this.overlay = true;
            setTimeout(function(){ location.reload() }, 2500);
          })

    },
    addRounding(x){
      if(typeof Number.parseFloat === "function"){
        return Math.round(100 * Number.parseFloat(x)) / 100;
      }
      console.warn('can not round financial parseFloat not a function');
      return x.toFixed(2);
    },
    playVideo(x){
      document.getElementById(x).play();
    },
    pauseVideo(x){
      document.getElementById(x).pause();
    }
  },
  beforeMount () {
    if (window.location.pathname !== '/') {
      axios
          .get(APIURL+'/CustomerPortal/api' + window.location.pathname)
          .then(
              response => {
                this.serviceAdvisor = response.data.serviceAdvisor;
                this.dealer = response.data.dealer;
                this.appointment = response.data.appointment;
                this.steps = response.data.steps;
                this.interventions = response.data.interventions;
                this.potentials = response.data.potentials;
                this.videos = response.data.videos;
                this.featuredVideo = response.data.featuredVideo;
                this.encryptedCustomerPortalId = response.data.encryptedCustomerPortalId;
                this.unselectedRowIds = response.data.unselectedRowIds;
                this.translations = response.data.translations;
              }
          )
          .catch( () => {
            this.loading = false;
            this.loadingError = true;

          })
          .finally(() => {
            this.loading = false;
            this.loadingError = false;
          })
    } else {
      this.loading = false;
      this.loadingError = true;
      this.noToken = true;
    }
  }

};
</script>

<style>
.v-btn.primary{
  font-weight: bold;
  color: black !important;
}

.v-stepper__step__step.primary{
  color: black !important;
  font-weight: bold;
}
</style>