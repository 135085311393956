import colors from 'vuetify/lib/util/colors'

const themeOptions = {
  options: {
    customProperties: true,
    variations: true
  },
  themes: {
    light: {
      primary: colors.yellow.darken1,
      secondary: colors.black,
      accent: colors.grey.darken4,
      error: colors.red.base,
      info: colors.lightBlue.darken1,
      success: colors.green.base,
      warning: colors.amber.base
    },
    dark: {
      primary: colors.yellow.darken1,
      secondary: colors.black,
      accent: colors.grey.darken4,
      error: colors.red.base,
      info: colors.lightBlue.darken1,
      success: colors.green.base,
      warning: colors.amber.base
    }
  }
}

const iconValues = {
  account: 'mdi-account-outline',
  apps: 'mdi-apps',
  bell: 'mdi-bell',
  close: 'mdi-close',
  cog: 'mdi-cog-outline',
  cube: 'mdi-cube-outline',
  lock: 'mdi-lock-outline',
  logout: 'mdi-logout-variant',
  search: 'mdi-magnify',
  tune: 'mdi-tune',
  video: 'mdi-video'
}

export { iconValues, themeOptions }
