<template>
<v-card max-width="300px" min-width="300px" class="text-center" >
<v-rating
    hover
    length="5"
    size="12"
    :value="Number(rating)"
    :readonly="Number(rating)>0"
    @input="addRating($event, videoid)"

></v-rating>
<div class="video_wrapper" style="position: relative;">

  <video
      ref="videoplayer"
      :src="src"
      :poster="poster"
      class="white--text align-end"
      width="100%"
  ></video>
</div>

<v-card-actions>
  <v-spacer></v-spacer>
  <v-btn icon @click="play">
    <v-icon>fal fa-play-circle</v-icon>
  </v-btn>
  <v-btn icon @click="pause">
    <v-icon>fal fa-pause-circle</v-icon>
  </v-btn>
  <v-btn icon @click="pause">
    <v-icon>fal fa-stop-circle</v-icon>
  </v-btn>
  <v-spacer></v-spacer>
</v-card-actions>
</v-card>
</template>
<script>
export default {
  name: 'videoplayer',
  props : {
    src : {type:String},
    poster : {type:String},
    rating : {type:[Number,String]},
    videoid : {type:String},
    addRating : Function
  },
  methods : {

        play () {
          this.$refs.videoplayer.play()
        },
        pause () {
          this.$refs.videoplayer.pause()
        },

  },
  data () {
    return {}

  }
}
</script>